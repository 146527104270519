<!--
 * @Descripttion: 购买页面
 * @Author: 张明尧
 * @Date: 2021-02-24 13:44:42
 * @LastEditTime: 2021-03-23 09:52:43
-->
<template>
  <div class="buy-div">
    <div class="buy-div-content">
      <div class="buy-div-content-title">
        请选择适合您的版本
      </div>
      <div class="buy-div-content-table-main">
        <el-row>
            <el-col v-for="(title, _title) in tableTitle" :key="`buy_title_${_title}}`" :span="4">
              <div class="buy-div-content-table-title" :style="_title == tableTitle.length -1 && 'border-right: none'">
                {{title.name}}
              </div>
            </el-col>
        </el-row>
        <el-row v-for="(data, _data) in tableData" :key="`buy_data_${_data}}`">
          <el-col :span="4">
            <div class="buy-div-content-table-title buy-div-content-table-name buy-div-content-table-list">
              {{data.name}}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="buy-div-content-table-title buy-div-content-table-user buy-div-content-table-list">
              {{data.users}}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="buy-div-content-table-title buy-div-content-table-year buy-div-content-table-list">
              <span @click="buyChange(data.years, 'year', data)" class="cursor-pointe-list" :class="data.years.year.check && 'buy-div-content-table-list-active'">{{data.years.year.name}}</span> /
              <span @click="buyChange(data.years, 'infinite', data)" class="cursor-pointe-list" :class="data.years.infinite.check && 'buy-div-content-table-list-active'">{{data.years.infinite.name}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="buy-div-content-table-title buy-div-content-table-developKF buy-div-content-table-list">
              <span @click="buyChange(data.developKF, 'open', data)" class="cursor-pointe-list" :class="data.developKF.open.check && 'buy-div-content-table-list-active'">{{data.developKF.open.name}}</span> /
              <span @click="buyChange(data.developKF, 'close', data)" class="cursor-pointe-list" :class="data.developKF.close.check && 'buy-div-content-table-list-active'">{{data.developKF.close.name}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="buy-div-content-table-title buy-div-content-table-deployType buy-div-content-table-list">
              {{data.deployType}}
            </div>
          </el-col>
          <el-col :span="4">
            <div style="border-right: none" class="buy-div-content-table-title buy-div-content-table-money buy-div-content-table-list">
              <el-button @click="phoneDialogVisible = true" v-show="data.years.infinite.check" type="primary">立即咨询</el-button>
              <span v-show="!data.years.infinite.check">
                <span style="font-size: 20px; transition: 1s;">￥</span>{{data.overMoney}}
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="buy-div-content-title margin-top-100">
        客户常见问题
      </div>
      <div v-for="(que, _que) in questionArr" :key="`question_buy_${_que}`" class="buy-div-content-question">
        <div class="buy-div-content-question-title">
          <div :style="`color: ${que.xhColor};background: ${que.xhBackground}`" class="buy-div-content-question-title-xh">
            {{_que + 1}}
          </div>
          {{que.title}}
        </div>
        <div class="buy-div-content-question-content" v-html="que.content">
        </div>
      </div>
      <el-dialog
        custom-class="phoneDialog"
        :visible.sync="phoneDialogVisible"
        append-to-body
        destroy-on-close
        @open="dialogOpened">
        <div
          slot="title"
          class="serviceTitle"
        >
          <span>电话咨询</span>
        </div>
        <div
          v-for="item in persons"
          :key="item.order"
          class="smlist"
        >
          <i
            class="el-icon-phone-outline font-size-24"
          />
          <span
            class="margin-left-5 font-weight-600 font-size-24"
          >{{ item.name }}:</span>
          <span
            class="margin-left-5 font-size-24"
            style="color: #F8A634"
          >{{ item.phone }}</span>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      now_index: 0, // 当前移入的鼠标
      tableTitle: [
        {
          name: '版本',
        },
        {
          name: '用户数',
        },
        {
          name: '年限',
        },
        {
          name: '二次开发',
        },
        {
          name: '部署方式',
        },
        {
          name: '价格（元）',
        },
      ], // 标题的数组
      tableData: [
        {
          name: '团队版',
          users: '30个用户',
          years: {
            year: {
              name: '1年',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
            infinite: {
              name: '无限',
              check: false,
              addMoney: 47400, // 选中后新增的价格
            }
          },
          developKF: {
            open: {
              name: '打开',
              check: false,
              addMoney: 3950, // 选中后新增的价格
            },
            close: {
              name: '关闭',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
          },
          deployType: '私有化',
          money: 7900,
          overMoney: 7900,
        },
        {
          name: '标准版',
          users: '100个用户',
          years: {
            year: {
              name: '1年',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
            infinite: {
              name: '无限',
              check: false,
              addMoney: 104300, // 选中后新增的价格
            }
          },
          developKF: {
            open: {
              name: '打开',
              check: false,
              addMoney: 7450, // 选中后新增的价格
            },
            close: {
              name: '关闭',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
          },
          deployType: '私有化',
          money: 14900,
          overMoney: 14900,
        },
        {
          name: '加强版',
          users: '200个用户',
          years: {
            year: {
              name: '1年',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
            infinite: {
              name: '无限',
              check: false,
              addMoney: 231200, // 选中后新增的价格
            }
          },
          developKF: {
            open: {
              name: '打开',
              check: false,
              addMoney: 14450, // 选中后新增的价格
            },
            close: {
              name: '关闭',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
          },
          deployType: '私有化',
          money: 28900,
          overMoney: 28900,
        },
        {
          name: '旗舰版',
          users: '无限',
          years: {
            year: {
              name: '1年',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
            infinite: {
              name: '无限',
              check: false,
              addMoney: 475000, // 选中后新增的价格
            }
          },
          developKF: {
            open: {
              name: '打开',
              check: false,
              addMoney: 25000, // 选中后新增的价格
            },
            close: {
              name: '关闭',
              check: true,
              addMoney: 0, // 选中后新增的价格
            },
          },
          deployType: '私有化',
          money: 50000,
          overMoney: 50000,
        },
      ],
      questionArr: [
        {
          title: '企业大脑的核心价值是什么？',
          content: '答：为企业提供数据互联互通的一揽子信息化解决方案，基于JEPaaS开发，具备极高的扩展性。',
          xhColor: '#F7875F',
          xhBackground: '#FFE9E1',
        },
        {
          title: '企业大脑最适合什么样的企业？',
          content: '答：项目驱动型企业，如：软件公司、工程公司、生产制造企业、科技服务企业等...',
          xhColor: '#EF4D4D',
          xhBackground: '#FFE1E1',
        },
        {
          title: '产品是私有化部署，还是云端在线试用？',
          content: '答：产品是私有化部署到您自己的服务器中，您也可委托我们帮您运维云端服务器。',
          xhColor: '#6A9CFF',
          xhBackground: '#D0DFFD',
        },
        {
          title: '产品搭建需要最低服务器配置是什么？',
          content: '答：团队版，需要一台4核8G的服务器即可（window或者linux）。',
          xhColor: '#288C4E',
          xhBackground: '#CDF3DB',
        },
        {
          title: '产品可以进行二次开发吗？',
          content: '答：企业大脑基于低代码平台（JEPaas）开发，您可采购二次开发功能自行对系统进行改造升级。',
          xhColor: '#7E9509',
          xhBackground: '#F1F6D8',
        },
        {
          title: '产品升级是免费的吗？',
          content: '答：服务期内，小版本迭代全是免费的。',
          xhColor: '#FA541C',
          xhBackground: '#FFF2E8',
        },
        {
          title: '产品如何试用？',
          content: '答：您可以在官网线上体验产品、也可拨打授权电话询问产品私有化下载地址。',
          xhColor: '#C8AC24',
          xhBackground: '#FEF8DB',
        },
        {
          title: '企业大脑会有哪些费用？',
          content: '答：第一、软件本身费用（必选，参考上面表格）。<div style="text-indent:2em">第二、项目实施费用（可选，包括安装部署，数据导入，现场教学等）。</div><div style="text-indent:2em">第三、二次开发费用（可选，针对您的需求对现有产品进行改造，按照工作量收费）。</div> <div style="text-indent:2em">第四、硬件运维费用（可选，如果您委托我们采购云服务器并运维，会有硬件采购和运维费用）。</div>',
          xhColor: '#33C0AD',
          xhBackground: '#D2FFF9',
        },
        {
          title: '企业大脑可以二次加工后出售给其他用户吗？',
          content: '答：可以的，需要与售前人员协商授权码相关事宜。',
          xhColor: '#9842D1',
          xhBackground: '#F9F0FF',
        },
        // {
        //   title: '产品可以在android、IOS、企业微信、钉钉、飞书、华为WeLine上使用吗？',
        //   content: '答：没问题。',
        //   xhColor: '#C41D7F',
        //   xhBackground: '#FFF0F6',
        // },
      ],
      persons: [
        {
          name: '石经理',
          phone: '18519706638'
        },
        {
          name: '赵经理',
          phone: '17610358522'
        },
        {
          name: '云经理',
          phone: '18610941078'
        },
      ],
      phoneDialogVisible: false,
    }
  },
  methods: {
    dialogOpened() {
      this.persons.sort(() => Math.random() - 0.5);
      this.persons.sort(() => Math.random() - 0.5);
    },
    // 改变当前的选中
    buyChange(obj, key, dataObj) {
      if(obj[key].check) return;
      const key_arr = Object.keys(obj);
      key_arr.forEach(keyrr => {
        if(keyrr != key) obj[keyrr].check = false;
      })
      obj[key].check = true;
      this.changeMoney(dataObj);
    },
    // 计算金钱
    changeMoney(obj) {
      if(obj.developKF.open.check && obj.years.infinite.check) return;
      obj.overMoney = obj.money
      obj.developKF.open.check && (obj.overMoney += obj.developKF.open.addMoney);
      // obj.years.infinite.check && (obj.overMoney += obj.years.infinite.addMoney);
    },
  }
}
</script>

<style lang="scss">
.phoneDialog {
  width: 550px !important;
  border-radius: 20px !important;
  padding: 0!important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0!important;
  transform: translate(-50%,-50%);
  .margin-left-5 {
    margin-left: 5px;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .el-dialog__header {
    padding: 0!important;
  }
  .serviceTitle {
    padding: 20px 20px;
    border-radius: 10px;
    color: #ffffff;
    height: 168px;
    font-size: 20px;
    background: url('../../assets/ktdn/dialogBanner.png') !important;
    background-size: 101% auto !important;
    background-repeat: no-repeat !important;
  }
  .el-dialog__headerbtn .el-icon-close{
    font-size: 25px;
    color: #ffffff!important;
  }
  .el-dialog__title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .el-dialog__body {
    font-size: 20px;
    font-weight: 400;
    color: rgba(63, 63, 63, 1);
    padding-top: 10px;
    padding-bottom: 50px;
    .smlist {
      padding: 10px 0;
      text-align: center;
    }
  }
}
</style>

<style lang="scss" scoped>
  .buy-div {
    width: 1400px;
    margin: 0 auto;
    color: #3f3f3f;
    padding-top: 80px;
    padding-bottom: 200px;
    min-height: 100vh;
    .margin-top-100 {
      margin-top: 100px;
    }
    .cursor-pointe-list {
      cursor: pointer;
    }
    .buy-div-content {
      .buy-div-content-table-main {
        margin-top: 70px;
        border-radius: 20px;
        box-shadow: 1px 0px 20px 0px rgba(180, 179, 179, 0.5);
        .buy-div-content-table-title {
          text-align: center;
          height: 120px;
          font-size: 20px;
          line-height: 120px;
          border-bottom: 1px solid #D8D8D8;
          border-right: 1px solid #D8D8D8;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .buy-div-content-table-list {
          border-bottom: none;
          font-size: 18px;
        }
        .buy-div-content-table-list-active {
          color: #FF6F45;
        }
        .buy-div-content-table-name {
          font-weight: 600;
        }
        .buy-div-content-table-money {
          color: #0358FD;
          font-size: 30px;
        }
      }
      .buy-div-content-title {
        font-size: 35px;
        font-weight: 600;
        text-align: center;
      }
      .buy-div-content-question {
        background: #FFFFFF;
        min-height: 210px;
        box-shadow: 1px 0px 20px 0px rgba(180, 179, 179, 0.5);
        border-radius: 0px 100px 0px 100px;
        margin-top: 80px;
        padding-left: 150px;
        padding: 40px 60px 60px 150px;
        .buy-div-content-question-title {
          font-size: 24px;
          font-weight: 600;
          position: relative;
          .buy-div-content-question-title-xh {
            position: absolute;
            text-align: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            line-height: 60px;
            font-size: 22px;
            font-weight: 600;
            left: -80px;
          }
        }
        .buy-div-content-question-content {
          font-size: 20px;
          margin-top: 30px;
          line-height: 1.7;
        }
      }
    }
  }
</style>